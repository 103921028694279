import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { AuthService } from '@shared/authorization/auth.service';
import { NOT_FOUND_ROUTE, UNAUTHORIZED_ROUTE } from '@shared/consts/routes.constants';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationGuard implements CanActivate {
  isFirstVisit: boolean = true;

  constructor(
    private router: Router,
    private authService: AuthService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const allowedRoles = route.data?.roles;
    const urlRedirect: string = this.isFirstVisit ? UNAUTHORIZED_ROUTE : NOT_FOUND_ROUTE;
    this.isFirstVisit = false;

    return this.authService.token$.pipe(
      tap((token) => this.authService.updateRoles(token)),
      switchMap(() => of(this.authService.hasRole(allowedRoles))),
      map((hasRole) => (hasRole ? true : this.router.createUrlTree([urlRedirect])))
    );
  }
}
